<template>
  <div class="undeveloped">
    <Wait/>
  </div>
</template>
<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Wait from "@/components/Wait";

function Component(initialize) {
  this.name = "pageAdminUndeveloped";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Wait},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>
<style lang="scss" scoped>
.undeveloped {
  padding-top: $px55;
}
</style>